
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IOrganization } from '../../entities/organization';
import { createOrg } from '@/services/steamcord';
import { validateCreateOrgData } from '@/services/validation/org';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import DashboardNavBar from '@/components/dashboard/DashboardNavBar.vue';

export default defineComponent({
  components: { DashboardNavBar },
  computed: {
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
    ...mapWritableState(useOrgStore, ['currentOrg']),
  },
  data() {
    return {
      createOrgData: { name: '', slug: '' },
      createOrgErrors: {
        nameError: '',
        slugError: '',
        hasErrors: false,
      },
      loading: false,
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (this.loading) {
        return;
      }

      this.createOrgErrors = validateCreateOrgData(this.createOrgData);
      if (this.createOrgErrors.hasErrors) {
        return;
      }

      this.loading = true;
      let org: IOrganization;

      try {
        org = await createOrg(this.createOrgData);
        this.currentOrg = org;
        this.$router.push({ name: 'Settings', params: { subdomain: this.createOrgData.slug } });
      } catch (err) {
        this.createOrgErrors.slugError = 'Subdomain is already in use.';
        this.loading = false;
      }
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
